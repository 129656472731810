html {
  scroll-behavior: smooth;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-right: 16px;
}

nav ul li a {
  text-decoration: none;
  color: #333;
}

nav ul li a:hover {
  text-decoration: underline;
}

.header-hero {
  height: 50vh; /* Full viewport height */
  background-image: url('../assets/bg-header.jpg');
  background-size: cover; /* Cover the entire section */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent image repetition */
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  color: white;
  text-align: center;
}

.header-hero-content {
  background-color: rgba(0, 0, 0, 0.5); /* Optional: Add overlay for better text readability */
  padding: 20px;
  border-radius: 10px;
}

.header-hero h1 {
  font-size: 3rem;
  margin: 0;
  font-weight: bold; /* Make the h1 text bold */
}

.header-hero p {
  font-size: 1.5rem;
  font-weight: bold; /* Make the p text bold */
}