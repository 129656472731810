/* TwoColumnSection.css */
.two-column-section {
  display: flex;
  flex-wrap: wrap; /* Ensure responsiveness */
  align-items: center;
  justify-content: center;
  gap: 16px; /* Add space between columns */
  margin-left: 16px;
  margin-right: 16px;
}

.about-column {
  flex: 1; /* Allow columns to share equal space */
  min-width: 300px; /* Ensure columns don't shrink too much */
}

.description {
  background-color: white;
}

.description h2 {
  font-size: 2rem;
  margin-bottom: 8px;
  font-weight: bold; /* Make the h1 text bold */
}

.description p {
  font-size: 1.2rem;
  line-height: 1.6;
}

.photo img {
  width: 60%; /* Make the image responsive */
  height: auto;
  border-radius: 12px; /* Optional: Add rounded corners */
  object-fit: cover; /* Ensure the image fits nicely */
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .two-column-section {
    flex-direction: column; /* Stack columns vertically */
    text-align: center; /* Center-align text on smaller screens */
  }

  .photo img {
    max-width: 90%; /* Reduce image size for smaller screens */
    margin: 0 auto;
  }
}